




































































































































































import TriggersListSectionTitle from '@/components/TriggersListSectionTitle.vue'
import checkChat from '@/assets/utils/checkChat'
import VuTriggers from '@/includes/logic/VuTriggers'
import { InputSetups } from '@/mixins/input-setups'
import TriggerCardListWrapper from '@/components/TriggerCardListWrapper.vue'
import { TriggersMode } from '@/includes/types/triggers/Enums'
import TriggersMixin from '@/mixins/TriggersMixin'
import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";
import TriggersListMixin from "@/mixins/TriggersListMixin";
import { BaseChatInfoNetworkData } from '@/includes/types/Chat/types'
import NetworksHelperMixin from "@/includes/logic/Networks/NetworksHelperMixin";
import NetworkChatEntities, { NetworkChatEntitiesData } from "@/components/networks/NetworkChatEntities.vue";

import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'
import SearchTextInput from 'piramis-base-components/src/components/Pi/fields/SearchTextInput/SearchTextInput.vue'
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'

import { Component, Mixins, Watch } from 'vue-property-decorator'
import arrayMove from 'array-move'
import { groupBy } from "lodash";

@Component({
  'components': {
    NetworkChatEntities,
    TriggersListSectionTitle,
    TriggerCardListWrapper,
    Icon,
    HelpMessage,
    SearchTextInput,
    SwitchInput
  },
})
export default class TriggersList extends Mixins(InputSetups, TriggersMixin, TriggersListMixin, NetworksHelperMixin) {
  triggersLoading = false

  netTriggers: NetworkChatEntitiesData = []

  @Watch('$route.params.CHAT_ID')
  onChatIdChange() {
    this.updateTriggers()
  }

  get triggersMode(): boolean {
    return this.$store.state.chatState.chat.triggers_mode === TriggersMode.Parallel
  }

  set triggersMode(value: boolean) {
    const triggers_mode = value ? TriggersMode.Parallel : TriggersMode.Linear

    this.$store.dispatch('saveChatConfig', { triggers_mode })
  }

  get inactiveTriggers(): Array<any> {
    return this.getFilteredChatTriggers('disabled')
  }

  get searchTriggers() {
    return this.queriedTriggers(this.$store.state.triggersState.frontendTriggers, this.sortByActiveTriggerExecutionOrder)
  }

  get activeTriggers(): Array<any> {
    return this.getFilteredChatTriggers('active')
  }

  get hasTriggerLimits(): string {
    let message = ''

    const chat = this.$store.state.chatState.chat
    const limits = chat.limits
    const max_triggers = limits.max_triggers
    const triggersLength = chat.triggers.length

    if (triggersLength > max_triggers) {
      message += `<span>${ this.$t('max_triggers') }: <b>${ triggersLength }</b> ${ this.$t('of') } <b>${ max_triggers }</b></span>`
    }

    return message
  }

  prepareNetworkTriggers() {
    const netTriggers = (this.$store.state.triggersState.triggers?.triggers ?? []).filter((t: RawTriggerData) => t.source_type === 'Network')
    const groupedByNetwork = groupBy(netTriggers, 'chat_id')

    Object.entries(groupedByNetwork).forEach(([ netId, triggers ]) => {
      const network = this.$store.state.chatState.chat?.networks?.find((n: BaseChatInfoNetworkData) => n.id === +netId)

      if (network) {
        this.netTriggers.push({
          network,
          data: triggers
        })
      }
    })
  }

  moveToDirection(data: { id: string, direction: 'left' | 'right' }): void {
    const { id, direction } = data
    const index = this.getActiveChatTriggersIds.indexOf(id)
    const movedTriggers = arrayMove(this.getActiveChatTriggersIds, index, direction === 'left' ? index - 1 : index + 1)

    this.$store.dispatch('saveChatConfigRequest', { triggers: movedTriggers })
  }

  moveToIndex(data: { id: string, newIndex: number }): void {
    const { id, newIndex } = data
    const index = this.getActiveChatTriggersIds.indexOf(id)
    const movedTriggers = arrayMove(this.getActiveChatTriggersIds, index, newIndex)

    this.$store.dispatch('saveChatConfigRequest', { triggers: movedTriggers })
  }

  hasLimits(trigger: any): any {
    let message = ''

    const limits = this.$store.state.chatState.chat.limits
    const max_trigger_actions = limits.max_trigger_actions
    const max_trigger_conditions = limits.max_trigger_conditions

    const count = (data: any) => {
      let i = 0

      if (data && data.length) {
        data.forEach(function(val: any) {
          if (val.actions) {
            val.actions.forEach(() => i++)
          } else {
            val.forEach(() => i++)
          }

        })
      }

      return i
    }

    const actionsCount = count(trigger.actions)
    if (actionsCount > max_trigger_actions) {
      message += `<p>${ this.$t('max_trigger_actions') }: <b>${ actionsCount }</b> ${ this.$t('of') } <b>${ max_trigger_actions }</b></p>`
    }

    const conditionsCount = count(trigger.conditions)
    if (conditionsCount > max_trigger_conditions) {
      message += `<p>${ this.$t('max_trigger_conditions') }: <b>${ conditionsCount }</b> ${ this.$t('of') } <b>${ max_trigger_conditions }</b></p>`
    }

    return message
  }

  removeTrigger(id: string): void {
    VuTriggers.deleteFrontendTrigger(id).then(() => {
      this.$store.dispatch('requestChatConfig', { id: this.$store.getters.chatId })
    })
  }

  gotoTrigger(actionType: 'new' | 'edit' | 'watch', triggerId?: string): void {
    const params: any = {}

    if (triggerId) {
      params['triggerId'] = triggerId
    }

    if (checkChat(this.$route.path)) {
      this.$router.push({
        name: 'chat_trigger',
        params: {
          [EntityTypes.CHAT_ID]: this.$store.state.chatState.chat.chat_id,
          actionType,
          ...params
        },
      })
    } else {
      this.$router.push({
        name: 'global_trigger',
        params: {
          actionType,
          ...params
        },
      })
    }
  }

  editTrigger(id: string): void {
    this.gotoTrigger('edit', id)
  }

  watchTrigger(id: string): void {
    this.gotoTrigger('watch', id)
  }

  copyTrigger(id: string): void {
    VuTriggers.copyTrigger(id)
    this.gotoTrigger('new')
  }

  addToChat(id: string): void {
    this.$store.commit('enableTrigger', id)

    this.$store.dispatch('saveChatConfigRequest', { triggers: this.$store.state.chatState.chat?.triggers })
  }

  removeFromChat(id: string): void {
    this.$store.commit('disableTrigger', id)

    this.$store.dispatch('saveChatConfigRequest', { triggers: this.$store.state.chatState.chat?.triggers })
  }

  updateTriggers(): void {
    this.triggersLoading = true

    this.$store.dispatch('updateTriggers')
      .finally(() => {
        this.prepareNetworkTriggers()

        this.triggersLoading = false
      })
  }

  mounted(): void {
    this.updateTriggers()
  }
}
